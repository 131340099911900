import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SidebarToggleService } from '../../services/sidebarToggle.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  transportista: boolean = false;
  carregador: boolean = false;
  @Input() admin: boolean = false;
  show: boolean = false;
  currentRoute: string = '';
  private sidebarToggleSubscription!: Subscription;

  constructor(private router: Router, private sidebarToggleService: SidebarToggleService) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.urlAfterRedirects;
      }
    });
  }

  async ngOnInit(): Promise<void> {
    const empresa = JSON.parse(localStorage.getItem("empresa") || "{}");
    if(empresa.rol) {
      this.carregador = empresa.rol === "CARRTRANSP"  || empresa.rol === "CARREGADOR"
      this.transportista = empresa.rol === "CARRTRANSP" || empresa.rol === "TRANSPORTISTA"
    }
    this.sidebarToggleSubscription = this.sidebarToggleService.toggleSidebar$.subscribe(() => {
      this.show = !this.show;
    });
  }

  ngOnDestroy(): void {
    this.sidebarToggleSubscription.unsubscribe();
  }

  isActive(route: string): boolean {
    return this.currentRoute.includes(route);
  }

  closeSidebar(route: string) {
    this.sidebarToggleService.emitSetIconHeader();
    window.location.href = `./#${route}`;
  }
}
