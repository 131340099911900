import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IniciComponent } from './pages/inici/inici.component';
import { RegistreComponent } from './pages/registre/registre.component';
import { ValidComponent } from './pages/valid/valid.component';
import { PerfilComponent } from './pages/perfil/perfil.component';
import { CarregadorComponent } from './pages/carregador/carregador.component';
import { TransportistaComponent } from './pages/transportista/transportista.component';
import { AdministracioComponent } from './pages/administracio/administracio.component';
import { StmcComponent } from './pages/stmc/stmc.component';
import { authGuard } from './guards/auth.guard';
import { ErrorComponent } from './pages/error/error.component';
import { DcComponent } from './pages/dc/dc.component';
import { CarregaComponent } from './pages/carrega/carrega.component';

const routes: Routes = [
  { path: 'inici', component: IniciComponent, canActivate: [authGuard] },
  { path: 'registre', component: RegistreComponent },
  { path: 'perfil', component: PerfilComponent, canActivate: [authGuard] },
  { path: 'carregador/stmc', component: StmcComponent, canActivate: [authGuard]},
  { path: 'transportista/stmc', component: StmcComponent, canActivate: [authGuard]},
  { path: 'carregador/stmc/:id', component: StmcComponent, canActivate: [authGuard]},
  { path: 'transportista/stmc/:id', component: StmcComponent, canActivate: [authGuard]},
  { path: 'transportista/dc/:id/:prev', component: DcComponent, canActivate: [authGuard]},
  { path: 'carregador/dc/:id/:prev', component: DcComponent, canActivate: [authGuard]},
  { path: 'carregador', component: CarregadorComponent, canActivate: [authGuard]},
  { path: 'transportista', component: TransportistaComponent, canActivate: [authGuard]},
  { path: 'administracio', component: AdministracioComponent , canActivate: [authGuard]},
  { path: 'carrega-massiva', component: CarregaComponent , canActivate: [authGuard]},
  { path: '', component: ValidComponent },
  { path: 'error', component: ErrorComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
