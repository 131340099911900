import {
  Component,
  Inject,
  EventEmitter,
  Output,
  inject,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { last, lastValueFrom } from 'rxjs';
import { ErrorModalComponent } from '../error-modal/error-modal.component';
import { StmcPopulated } from '../../types/StmcPopulated';
import { StmcService } from '../../services/api/stmc.service';
import { ToastComponent } from '../toast/toast.component';
import { ContextService } from '../../services/api/context.service';

@Component({
  selector: 'app-user-edit',
  styleUrl: './email-dc.component.scss',
  template: `
    <div class="ctti-modal text-left" [formGroup]="form">
      <div class="cross" (click)="closeModal()">
        <mat-icon> close </mat-icon>
      </div>
    <h5>{{ 'DC.TITLE_EMAIL' | translate }}</h5>
    <hr />
    <p>{{ 'DC.SUBTITLE_EMAIL' | translate }}</p>
    <div class="content">
        <div class="grid-2">
          @if(this.nomCarregador && this.emailCarregador) {
          <div class="input">
            <mat-checkbox
              id="carregador"
              [checked]="isChecked('CARR')"
              (change)="onRoleChange('CARR')"
              ><span class="marge">{{
                'DC.INFORMAR_C' | translate
              }}</span></mat-checkbox
            >
          </div>
          }@else{ <div></div>}
          @if(this.nomTransportista && this.emailTransportista) {
          <div class="input">
            <mat-checkbox
              id="transportista"
              [checked]="isChecked('TRAN')"
              (change)="onRoleChange('TRAN')"
              ><span class="marge">{{
                'DC.INFORMAR_T' | translate
              }}</span></mat-checkbox
            >
          </div>
          }@else{ <div></div>}
          @if(this.nomCarregador) {
          <div class="dada-smtc">
            <div>
              <!-- <mat-icon>forklift</mat-icon> -->
              <img width="24" heigth="24" src="../../../assets/images/forklift.png" alt="carregador"/>
            </div>
            <p>{{ this.nomCarregador }}</p>
          </div>
          }@else{ <div></div>}
          @if(this.nomTransportista) {
          <div class="dada-smtc">
            <div><mat-icon>local_shipping</mat-icon></div>
            <p>{{ this.nomTransportista }}</p>
          </div>
          }@else{ <div></div>}
        </div>
        <p>{{ 'DC.EMAILS_LIST' | translate }}</p>
        <div>
          <label>{{ 'ADMIN.EMAIL' | translate }}:</label>
          <input
            matInput
            type="text"
            id="adrecaElectronica"
            formControlName="adrecaElectronica"
            placeholder="{{ 'MODAL.MAILS_PLACEHOLDER' | translate }}"
            class="ctti-input-text"
          />
        </div>
      </div>
      <div class="botones">
        <button
          class="ctti-button ctti-button-trans"
          mat-dialog-close
          (click)="closeModal()"
        >
          {{ 'MODAL.CANCEL' | translate }}
        </button>
        <button
          class="ctti-button ctti-button-filled"
          mat-dialog-close
          (click)="closeModal(true)"
        >
          <mat-icon>send</mat-icon>
          {{ 'DC.ENVIAR_EMAIL' | translate }}
        </button>
      </div>
    </div>
  `,
})
export class EmailDcComponent {
  stmcService = inject(StmcService);
  contextService = inject(ContextService);

  form!: FormGroup;
  toast!: ToastComponent;

  @ViewChild(ToastComponent)
  @Output()closeEvent = new EventEmitter<{save: boolean}>();

  informarCarregador: boolean = false;
  informarTransportista: boolean = false;
  emailCarregador: string = "";
  emailTransportista: string = "";
  nomTransportista: string = "" 
  nomCarregador: string = ""

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { stmc: StmcPopulated },
    private fb: FormBuilder,
    private dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    this.form = this.fb.group({
      adrecaElectronica: ['', Validators.required],
    });
    this.nomTransportista = this.data.stmc.transportistaEfectiu.raoSocial;
    this.nomCarregador = this.data.stmc.carregadorContractual.raoSocial;
    if(this.nomTransportista) {
      const transp = await lastValueFrom(this.contextService.getEmpreses(this.nomTransportista, "TRANSPORTISTA"))
      console.log(transp)
      if(transp.length) {
        this.emailTransportista = transp[0].adrecaElectronica
      }
    }
    if(this.nomCarregador) {
      const carr = await lastValueFrom(this.contextService.getEmpreses(this.nomCarregador, "CARREGADOR"))
      console.log(carr)
      if(carr.length) {
        this.emailCarregador = carr[0].adrecaElectronica
      }
    }
  }

  isChecked(item: string) {
    return item === 'CARR'
      ? this.informarCarregador
      : this.informarTransportista;
  }

  onRoleChange(item: string) {
    if (item === 'CARR') {
      this.informarCarregador = !this.informarCarregador;
      return;
    }
    this.informarTransportista = !this.informarTransportista;
  }

  errorValidator(error: any) {
    const callback = () => {
      const dialogRef = this.dialog.open(EmailDcComponent, {
        data: { stmc: this.data.stmc },
      });
      dialogRef.componentInstance.closeEvent.subscribe(() => {
        this.dialog.closeAll();
      });
    };
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      data: {
        title: 'ERRORS.'.concat(error.codiError),
        message: error.missatge,
        icon: 'warning',
        showCancel: false,
        callback,
      },
    });
    dialogRef.componentInstance.closeEvent.subscribe(
      async (result: { accept: boolean }) => {
        this.dialog.closeAll();
      }
    );
  }

  async closeModal(save: boolean = false) {
    if (save) {
      const emails = this.form.value.adrecaElectronica.split(";");
      emails.map((el: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (el && !emailRegex.test(el)) {
          this.errorValidator({codiError: 'EMAIL_NO', missatge: ""});
          return;
        }
      })
     
      const body = {
        codiStmc: this.data.stmc.codiStmc,
        adrecasElectronicas: emails.length ? emails : null,
        informarCarregador: this .informarCarregador,
        informarTransportista: this.informarTransportista,
        versio: null,
      };
      const res = await lastValueFrom(this.stmcService.sendByEmail(body));
      if (res.validacioResultat) {
        this.errorValidator(res.validacioResultat[0]);
        return;
      }
      this.stmcService.emitDcEnviat()
    }
    this.dialog.closeAll();
  }
}
